type GetRecommendedProductCnstrcAttrsProps = {
  initialAttributes?: object
  recommenderStrategies?: Record<string, string>
  uid: string
}

export const getRecommendedProductCnstrcAttrs = ({ initialAttributes, recommenderStrategies, uid }: GetRecommendedProductCnstrcAttrsProps) => {
  const strategyId = recommenderStrategies?.[uid]

  const attributes = { ...initialAttributes } || {}

  if (strategyId) {
    attributes['data-cnstrc-item'] = 'recommendation'
    attributes['data-cnstrc-strategy-id'] = strategyId
  }

  return attributes
}
