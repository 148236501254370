import { useMemo } from 'react'


type UseCnstrcRecommenderTrackingDataAttrsParams = {
  podId: string
  resultId?: string
  resultsCount: number
}

export const useCnstrcRecommenderTrackingDataAttrs = ({ podId, resultId, resultsCount }: UseCnstrcRecommenderTrackingDataAttrsParams) => {
  return useMemo(() => {
    const attributes = {
      'data-cnstrc-recommendations': true,
    }

    if (podId) {
      attributes['data-cnstrc-recommendations-pod-id'] = podId
    }

    if (resultId) {
      attributes['data-cnstrc-result-id'] = resultId
    }

    if (isFinite(resultsCount)) {
      attributes['data-cnstrc-num-results'] = resultsCount
    }

    return attributes
  }, [ podId, resultId, resultsCount ])
}
