import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { constants } from 'helpers'
import { useAb } from 'hooks'

import { useSubscription } from 'modules/subscription'
import { useUser } from 'modules/user'


type UseCnstrcRecommendationsEnabledProps = {
  ignoreAbTestForLeads?: boolean
}

export const useCnstrcRecommendationsEnabled = (props: UseCnstrcRecommendationsEnabledProps = {}) => {
  const { ignoreAbTestForLeads } = props

  const isConstructorRecommendationsFeatureEnabled = useFeatureIsOn(constants.features.constructorRecommendations)
  const isConstructorRecommendationsSubscribedEnabled = useFeatureIsOn(constants.features.constructorRecommendationsSubscribed)
  const isConstructorRecommendationsActiveLeadEnabled = useFeatureIsOn(constants.features.constructorRecommendationsActiveLead)
  const isConstructorRecommendationsTestEnabled = useAb(constants.abTests.constructorRecommendations) === 'b'

  const { isLoggedIn } = useUser()
  const { subscription } = useSubscription()

  const areConstructorRecommendationsEnabled =
    isLoggedIn
    && isConstructorRecommendationsFeatureEnabled
    // We show constructor recommendations for users with 'b' test value and all leads when corresponding feature + option is enabled
    && (isConstructorRecommendationsTestEnabled || (ignoreAbTestForLeads && isConstructorRecommendationsActiveLeadEnabled && !subscription?.isActive))
    && (isConstructorRecommendationsSubscribedEnabled || !subscription?.isActive)

  return areConstructorRecommendationsEnabled
}
