import type { ProductStrategy } from 'typings/graphql'


export const normalizeRecommenderStrategies = (productsStrategies: ProductStrategy[]) => {
  return Object.fromEntries(productsStrategies?.map((productStrategy) => {
    const { strategyId, uid } = productStrategy

    return [ uid, strategyId ]
  }) || [])
}
