import { useMemo } from 'react'
import { useIntl } from 'intl'


export const useCnstrcPDPTrackingDataAttrs = ({ id, name, prices, variationId }: ConstructorIO.ProductProps) => {
  const intl = useIntl()

  return useMemo(() => {
    const { standardPrice, discountPrice } = prices || {}

    const price = discountPrice?.amountCents || standardPrice?.amountCents
    const formattedPrice = price && intl.formatPrice(price, { withCurrency: false })

    return {
      'data-cnstrc-product-detail': true,
      'data-cnstrc-item-id': String(id),
      'data-cnstrc-item-name': name,
      'data-cnstrc-item-price': formattedPrice,
      'data-cnstrc-item-variation-id': variationId,
    }
  }, [ id, name, prices, variationId, intl ])
}
