import { useMemo } from 'react'


type CnstrcResultsTrackingType = 'browse' | 'search' | 'autosuggest'

type CnstrcResultsTrackingDataAttrs = Record<string, string | number | boolean>

type UseCnstrcResultsTrackingDataAttrsParams = {
  type: CnstrcResultsTrackingType
  count?: number
  filterName?: string // Should be empty string unless we get results from a Constructor.IO
  filterValue?: string // Should be Category ID unless we get results from a Constructor.IO
}

export const useCnstrcResultsTrackingDataAttrs = (params: UseCnstrcResultsTrackingDataAttrsParams) => {
  const { type, count = 0, filterName, filterValue } = params

  return useMemo(() => {
    const baseAttrs: CnstrcResultsTrackingDataAttrs = {
      [`data-cnstrc-${type}`]: true,
    }

    if (!Number.isNaN(count)) {
      baseAttrs['data-cnstrc-num-results'] = count
    }

    if (type === 'autosuggest') {
      baseAttrs['data-cnstrc-item-section'] = 'Search Suggestions'
    }

    if (type === 'search' || type === 'browse') {
      baseAttrs['data-cnstrc-filter-name'] = filterName ?? ''
      baseAttrs['data-cnstrc-filter-value'] = filterValue ?? ''
    }

    return baseAttrs
  }, [ type, count, filterName, filterValue ])
}
