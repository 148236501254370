export const cnstrcTrackPurchaseData = (purchaseData: PaymentModule.PurchaseSession) => {
  const { total, orders } = purchaseData.data
  // TODO: Investigate how to work with multiple orders — added on 12–02-2024 by algeas
  const [ order ] = orders ?? []

  let orderItems: ConstructorIO.OrderItem[] = []

  order?.items?.forEach((item) => {
    if (item.__typename === 'ProductPurchaseItem') {
      orderItems.push({
        item_id: item.product.uid,
        quantity: item.quantity,
        variation_id: item.tradingItem.sku,
      })
    }
  })

  if (!orderItems.length) {
    return
  }

  // TODO: Investigate that weird properties based mechanics — added on 12–02-2024 by algeas
  window.cnstrcPurchaseData = {
    items: orderItems,
    order_id: String(order.id),
    revenue: total / 100,
  }
}
